var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container" },
          [
            _c("base-search", {
              staticStyle: { display: "inline-block" },
              attrs: {
                searchList: _vm.$jczlNbiotzcbSearch,
                listQuery: _vm.listQuery,
              },
              on: {
                search: _vm.handleFilter,
                "btn-event": _vm.onBtnClicked,
                handleCommand: _vm.handleCommand,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item" },
        [
          _c(
            "el-row",
            { staticStyle: { height: "100%" }, attrs: { gutter: 8 } },
            [
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 4 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "body-small",
                      staticStyle: { height: "100%", overflow: "auto" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c("basic-tree", {
                        attrs: { Treedata: _vm.orgsTree, isShowCheck: true },
                        on: {
                          handleNodeClick: _vm.handleNodeClick,
                          getCheckedKeys: _vm.getCheckedKeys,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { height: "100%" }, attrs: { span: 20 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "bg-white",
                      staticStyle: { height: "100%" },
                      attrs: { id: "table_box_height" },
                    },
                    [
                      _vm.isFirstIn
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                margin: "20% 0 0 40%",
                                color: "#aaa",
                              },
                            },
                            [_vm._v("请选择左侧区域查询数据")]
                          )
                        : _c("basic-table", {
                            attrs: {
                              tableHeight: _vm.tableHeight,
                              tableHeaderList: _vm.$jczlNbiotzcbTableHeader,
                              tableData: _vm.tableData,
                              listQuery: _vm.listQuery,
                            },
                            on: {
                              pagination: _vm.handleCurrentChange,
                              tableRowClick: _vm.tableRowClick,
                            },
                          }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("general-form", {
        attrs: {
          FormDialog: _vm.FormDialog,
          FormList: _vm.FormList,
          title: _vm.addTitle,
          FormData: _vm.addform,
        },
        on: {
          cancelDialog: _vm.cancelDialog,
          confirmDialog: _vm.confirmDialog,
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini user-dialog",
          attrs: {
            title: _vm.instructionTitle,
            visible: _vm.instructionDialog,
            width: "20%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.instructionDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                model: {
                  value: _vm.addform.surfaceAddress,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "surfaceAddress", $$v)
                  },
                  expression: "addform.surfaceAddress",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "cancel" },
                  on: {
                    click: function ($event) {
                      _vm.instructionDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.instructionDialogConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }