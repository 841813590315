<template>
  <div class="flex-column">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlNbiotzcbSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;" @handleCommand="handleCommand"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <el-row style="height: 100%;" :gutter="8" >
        <el-col :span="4" style="height: 100%;">
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :Treedata="orgsTree" :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys"></basic-tree >
          </el-card>
        </el-col>
        <el-col :span="20" style="height: 100%;">
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <div v-if="isFirstIn" style="margin: 20% 0 0 40%;color: #aaa;">请选择左侧区域查询数据</div>
            <basic-table v-else :tableHeight="tableHeight" :tableHeaderList="$jczlNbiotzcbTableHeader" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick"></basic-table >
          </div>
        </el-col>
      </el-row>
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 指令弹窗 -->
    <el-dialog class="dialog-mini user-dialog" :title="instructionTitle" :visible.sync="instructionDialog" width="20%">
      <div>
        <el-input v-model="addform.surfaceAddress"></el-input>
      </div>
      <div style="text-align:right;" slot="footer">
        <el-button size="small" type="cancel" @click="instructionDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="instructionDialogConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import {listToTreeSelect} from '@/utils'
  import * as login from '@/api/login'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        isFirstIn:true,//首次进入页面
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          orgId: '',
          key: undefined
        },
        orgs: [], // 用户可访问到的组织列表
        orgsTree: [], // 用户可访问到的所有机构组成的树
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        tableChooseRow:{},//table选中数据
        FormDialog:false,//添加弹窗
        addform:{
          surfaceAddress:'',
          meterReadingTime:'',
          reportingCycle:'',
          lastUpdateReading:'',
          gzsb:'false'
        },
        FormList:{//表格列
          column:[
            {columnKey:'surfaceAddress',columnDescription:'表地址',columnType:'text',prop:'surfaceAddress',width:'120',placeholder:"表地址",},
            {columnKey:'meterReadingTime',columnDescription:'抄表时间',columnType:'text',prop:'meterReadingTime',width:'120',placeholder:"抄表时间",},
            {columnKey:'reportingCycle',columnDescription:'上报周期',columnType:'text',prop:'reportingCycle',width:'120',placeholder:"上报周期",},
            {columnKey:'lastUpdateReading',columnDescription:'最后更新读数',columnType:'text',prop:'lastUpdateReading',width:'120',placeholder:"最后更新读数",},
            {
              columnKey:'gzsb',
              columnDescription:'挂载设备',
              columnType:'select',
              placeholder:"请选择挂载设备",
              prop:'gzsb',
              width:'120',
              seleceOption:[
                {label:'否',value:'0'},
                {label:'是',value:'1'},
              ]
            },
          ],
          //校验
          rule:{
            surfaceAddress: [{ required: true, message: "表地址不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        instructionTitle:'',//指令弹窗标题
        instructionDialog:false,//指令弹窗控制
      }
    },
    watch: {

    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      this.getOrgTree()
      // 计算table高度
      this.$nextTick(()=>{
      let ele=document.getElementById("table_box_height");
      this.tableHeight=ele.offsetHeight-50
      window.addEventListener('resize', () => { 
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        this.$forceUpdate()
      });
      // 计算table高度
    })
    },
    activated() {
      // this.getList()
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        console.log(data)
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        this.CheckedKey = data
        var s = this.CheckedKey.length
        this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            this.handleDelete(this.tableChooseRow)
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          default:
            break
        }
      },
      // 下拉菜单事件
      handleCommand(val){
        if(this.tableChooseRow.serialNumber){
          this.instructionTitle = val.parameter
          this.instructionDialog = true;
          switch (val.parameter) {
            case '设置底数':
              
              break;
            case '设置表地址':
              
              break;
            case '设置上报周期':
              
              break;
            case '设置上报时间':
              
              break;
            case '设置冻结频率':
              
              break;
            case '设置密集冻结频率':
              
              break;
            case '设置无流量告警时间':
              
              break;
            case '设置过量自动关阀':
              
              break;
            case '开阀':
              
              break;
            case '关阀':
              
              break;
            case '阀门摆动':
              
              break;
            default:
              break;
          }
          // this.$message('click on item ' + val.command);
        }else{
          this.$message.error('请选择一个表进行操作！');
        }
        
      },
      // 指令弹窗确认
      instructionDialogConfirm(){
        this.instructionDialog = false;
        this.$message.success('操作成功');
      },
      // 获取数据
      getList() {
        // login.getSubOrgs(this.listQuery).then(response => {
        //   if(response.code == '200'){
        //     this.tableData = Object.assign([], response.data)
        //   }else{
        //     this.$message({
        //       message:response.message,
        //       type:'warning'
        //     })
        //     this.tableData = []
        //   }
        // }).catch(()=>{
        //   this.tableData = []
        // })
        this.tableData = this.$store.state.tableDataSearch
      },
      // 获取树形组件数据
      getOrgTree() {
        var _this = this // 记录vuecomponent
        login.getOrgs().then(response => {
          _this.orgs = response.result.map(function(item) {
            return {
              id: item.id,
              label: item.name,
              parentId: item.parentId || null
            }
          })
          var orgstmp = JSON.parse(JSON.stringify(_this.orgs))
          _this.orgsTree = listToTreeSelect(orgstmp)
        })
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.tableChooseRow = {}//重置选中项
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      handleDelete(row) { // 多行删除
        this.tableData.splice(this.tableData.findIndex(e=>e.serialNumber === row.serialNumber),1)
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
        this.$store.state.tableDataSearch.push(this.addform)
        this.addform={
          surfaceAddress:'',
          meterReadingTime:'',
          reportingCycle:'',
          lastUpdateReading:'',
          gzsb:'false'
        }
        this.FormDialog = false
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
